<template>
    <div class="card card-success">
        <div class="card-body">
            <table>
                <template v-for="field in this.fields" v-bind:key="field.id">
                    <tr>
                        <td class="head">
                            <span>{{ field.label }}：</span>
                        </td>
                        <td>
                            <component v-bind:is="field._component" v-bind:field="field"
                                v-bind:value="this.data[field.variable]" v-bind:message="message[field.variable]"
                                @input="input_event(field, $event)"></component>
                        </td>
                    </tr>
                </template>
            </table>
        </div>
        <div class="card-footer">
            <button type="button" class="btn btn-default btn-sm bg-green" v-show="false"><i
                    class="fas fa-list-ul"></i></button>
            <button type="button" @click="edit_row" class="btn btn-default btn-sm bg-blue"
                v-show="permissions['fix'] != undefined && permissions['fix'] == 'Y'"><i class="fas fa-edit"></i></button>
            <button type="button" @click="delete_row" class="btn btn-default btn-sm bg-red"
                v-show="permissions['delete'] != undefined && permissions['delete'] == 'Y'"><i
                    class="fas fa-trash-alt"></i></button>
            <template v-for="button in this.buttons" v-bind:key="button.label">
                <button type="button" @click="extend_row(button)" class="btn btn-default btn-sm"
                    v-bind:class="['bg-' + button.color]">
                    <i v-bind:class="button.icon"></i> {{ button.label }}</button>
            </template>
        </div>
    </div>
</template>


<script>
export default {
    props: ['id', 'data', 'properties'],
    data() {
        let localFields = this.properties.list_fields;
        let message = {};

        // 將欄位轉換成實體的元件
        for (let key in localFields) {
            localFields[key]._component =
                this.$microlink.components[localFields[key].component.type].component;
            message[localFields[key].variable] = '';
        }

        return {
            permissions: this.properties.permissions,
            fields: localFields,
            message: message,
            value: this.data,
            buttons: this.properties.button_fields,
            widget_properties: this.properties
        };
    },
    methods: {
        list: function () {
            this.$emit('row_event_click', 'list_row', { 'id': this.id });
        },
        edit_row: function () {
            this.$emit('row_event_click', 'edit_row', { 'id': this.id });
        },
        delete_row: function () {
            this.$emit('row_event_click', 'delete_row', { 'id': this.id });
        },
        extend_row: function (button_meta) {

            let button_link_meta = {};
            // let button_link_meta = JSON.parse(button_meta.widget);

            button_link_meta.widget_code = button_meta.widget;
            button_link_meta[button_meta.param] = this.id;

            // 麵包屑專用
            button_link_meta['breadcrumb_title'] = this.data['title'];

            this.$emit('row_event_click', 'extend_row', button_link_meta);
        },
        /**
     * 輸入事件
     * @param {*} meta 
     * @param {*} $event 
     */
        input_event(meta, $event) {

            let $this = this;

            this.value[meta.variable] = ($event.temp == undefined) ? $event.target.value : $event.temp;

            // 宣告參數物件
            let params = {};

            // 如果有「資料領域」的設定，則加入到參數中
            if (this.widget_properties.datazone_setting != undefined &&
                this.widget_properties.datazone_setting.datazone != undefined &&
                this.widget_properties.datazone_setting.datazone != '') {
                params['dz'] = this.widget_properties.datazone_setting.datazone;
            }

            // 參數的網址字串
            let parameters = '';
            // 將 params 物件轉換成網址字串
            // 如果面沒有參數，則不加入 & 字元
            for (let pkey in params) {
                if (parameters != '') {
                    parameters += '&';
                }
                parameters += `${pkey}=${params[pkey]}`;
            }

            if (parameters != '') {
                parameters = '?' + parameters;
            }

            // 組合 API 網址
            let api_url = this.$microlink.base_api_url + this.widget_properties.events.form_save + parameters;

            // 發送資料
            this.$microlink.api_post(api_url, this.value, true, function (response) {
                if (response.status == 'failed') {

                    // 欄位驗證錯誤
                    if (response.type == 'field-validate') {

                        // 將錯誤訊息顯示在表單上
                        for (var key in response.data) {
                            $this.message[key] = response.data[key];
                        }

                        // 顯示錯誤訊息
                        $this.$microlink.alert('儲存失敗', '請檢查欄位資料是否正確', 'error');

                    }
                    else {
                        $this.$microlink.alert('儲存失敗', response.message, 'error');
                    }

                }
            });
        }
    }
};
</script>

<style scoped>
table td.head {
    text-align: right;
}

.card-footer {
    text-align: right;
}
</style>